import Anime from "../Anime"
import { Components } from "../AnimeType";

export async function renderingSystem(anime: Anime, dt: number) {
  let ctx = anime.canvas.getContext("2d");
  let { width, height } = anime.canvas;
  if (!ctx) return;
  //ctx.clearRect(0, 0, anime.canvas.width, anime.canvas.height);
  for (const entityId of anime.entities) {
    let comp = anime.components;
    if (!comp) return console.log("No components in anime");
    
    // Check visibility
    if (comp?.position?.get(entityId) && !comp.position.get(entityId)?.visible) 
      continue;

    // Render if visible
    let elementType = comp?.elementType.get(entityId);

    if (elementType === "baseImage") {
      ctx.drawImage(comp?.image?.get(entityId)?.image, 0, 0, width, height);

    } else if (elementType === "baseVideo") {
      await renderBaseVideo(anime, dt, ctx, comp, entityId);

    } else if (elementType === 'hilight') {
      await renderHilight(anime, dt, ctx, comp, entityId);

    } else if (elementType === 'rectangle') {
      let left = comp?.position?.get(entityId)?.left as number || 0;
      ctx.fillStyle = "red";
      ctx.fillRect(left, 100, 60, 60);

    } else if (elementType ===  'text') {
      ctx.font = "30px Arial";
      ctx.fillStyle = "black";
      ctx.textAlign = "left"; 
      ctx.textBaseline = "middle";
      let text = comp?.textDisplay?.get(entityId)?.text as string || "";
      ctx.fillText(text.substring(0, comp?.textDisplay?.get(entityId)?.length || 0), 100, 200);
    }
  }
}

async function renderBaseVideo(anime: Anime, dt: number, ctx: CanvasRenderingContext2D, comp: Components, entityId: number) {
  let video = comp?.video?.get(entityId);
  if (video && !video?.locked) {
    video.locked = true;
    await new Promise((resolve, reject) => {
      if (!video || !video.video) 
        return reject("No video in components");
      video.video.onseeked = null;
      video.video.onseeked = () => {
        try {
          ctx?.drawImage(video?.video!, 0, 0, anime.canvas.width, anime.canvas.height);
          resolve("done");
        } catch (error: any) {
          reject(`Error drawing video frame: ${error.message}`);
        }
        if (video)
          video.locked = false;
      };
      let dt_ = video.timeMap ? video.timeMap(dt) : dt;
      video.video.currentTime = dt_;
    });
  }
}

async function renderHilight(anime: Anime, dt: number, ctx: CanvasRenderingContext2D, comp: Components, entityId: number) {
  // Position
  let {left, top} = comp?.position?.get(entityId) || {};
  left = left || 0;
  top = top || 0;
  let layout = comp?.layout?.get(entityId);
  let layoutWidth: number =layout?.width || 100;
  let layoutHeight :number = layout?.height || 100;

  // Content
  let text = comp?.textDisplay?.get(entityId)?.text as string || "";

  // Style
  ctx.font = "30px Arial";
  ctx.fillStyle = "black";
  ctx.textAlign = "left"; 
  ctx.textBaseline = "middle";
  ctx.strokeStyle = "red";
  ctx.lineWidth = 4;

  // Draw
  ctx.strokeRect(left, top, layoutWidth, layoutHeight);

  ctx.beginPath();
  ctx.moveTo(left + layoutWidth / 2, top + layoutHeight);
  ctx.lineTo(left + layoutWidth / 2, top + layoutHeight + 30);
  ctx.stroke();

  ctx.fillText(
    text.substring(0, comp?.textDisplay?.get(entityId)?.length || text.length), 
    left + layoutWidth / 2, 
    top + layoutHeight + 50);
}
