import Anime from "../Anime"
import { FreezePeriod } from "../AnimeType";

export async function assetLoadSystem(anime: Anime, dt: number) {
  let comp = anime.components;
  for (const entityId of anime.entities) {
    if (comp?.elementType.get(entityId) === "baseImage") {
      let image = comp?.image?.get(entityId);
      if (image && image?.url && !image?.image) {
        let loadedImage = await new Promise((resolve, reject) => {
          let img = document.createElement("img");
          img.crossOrigin = "anonymous";
          img.onload = () => resolve(img);
          img.src = image?.url || "";
        });
        image = {
          ...image,
          image: loadedImage,
          thumbUrl: image.url
        }
        comp.image?.set(entityId, image);
      }
    } else if (comp?.elementType.get(entityId) === "baseVideo") {
      let video = comp?.video?.get(entityId);
      if (video && video?.url && !video?.video) {
        let loadedVideo = document.createElement("video");
        loadedVideo.src = video.url;
        loadedVideo.crossOrigin = "anonymous";
        try {
          await waitForVideoReady(loadedVideo);
          //console.log(`Video is initialized with duration ${loadedVideo.duration}`);
        } catch (error) {
          console.error("Error initializing video:", error);
        }
        let additionalDuration = (video.freezePeriods || [])
          .map((period: FreezePeriod) => period.duration)
          .reduce((acc: number, cur: number) => acc + cur, 0);
        let totalDuration = loadedVideo.duration + additionalDuration;
        let timeMap = (input: number) => {
          let offset = 0;
          if (video?.freezePeriods?.length) {
            let freezePeriods = video.freezePeriods.sort((a: any, b: any) => a.start - b.start);
            for (var period of freezePeriods) {
              if (input - offset < period.start) {
                return input - offset
              } else if (input - offset < period.start + period.duration) {
                return period.start;
              } else {
                offset += period.duration;
              }
            }
            return input - offset; 
          } else {
            return input;
          }
        }
        video = {
          ...video,
          video: loadedVideo,
          totalDuration,
          thumbUrl: await getVideoThumbUrl(loadedVideo),
          timeMap,
        }
        comp.video?.set(entityId, video);
      }
    }
  }
}

function waitForVideoReady(video: HTMLVideoElement) {
  return new Promise((resolve: any, reject: any) => {
    if (video.readyState >= HTMLMediaElement.HAVE_ENOUGH_DATA) {
      // Video is already ready
      resolve();
    } else {
      const onReady = () => {
        resolve();
        cleanup();
      };

      const onError = (e: any) => {
        reject(new Error("Video failed to load."));
        cleanup();
      };

      const cleanup = () => {
        video.removeEventListener("loadeddata", onReady);
        video.removeEventListener("error", onError);
      };

      // Listen for readiness or error
      video.addEventListener("loadeddata", onReady); // Fired when enough data is available
      video.addEventListener("error", onError);
    }
  });
}

async function getVideoThumbUrl(video: HTMLVideoElement): Promise<string> {
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  video.onseeked = null;
  let result: string = await new Promise((resolve, reject) => {
    video.onseeked = () => {
      try {
        ctx?.drawImage(video, 0, 0, 300, 200);
        let url = canvas.toDataURL();
        resolve(url);
      } catch (error: any) {
        resolve("/blankscreen.png");
      }
    };
    video.currentTime = 0;
  });
  video.onseeked = null;
  return result;
}
