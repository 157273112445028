import Anime from "../Anime"

export async function textSystem(anime: Anime, dt: number) {
  let comp = anime.components;
  for (const entityId of anime.entities) {
    if (comp?.elementType.get(entityId) !== "text") continue;
    let textDisplay = comp?.textDisplay?.get(entityId);
    if (Number.isInteger(textDisplay?.length) && textDisplay?.length !== undefined) {
      textDisplay.length = Math.floor(dt);
      comp?.textDisplay?.set(entityId, textDisplay);
      // Old algorithm - keep for reference
      // if (textDisplay.lastChange === undefined)
      //   textDisplay.lastChange = 0;
      // if (dt - textDisplay.lastChange > 1) {
      //   textDisplay.length = Math.floor(dt);
      //   textDisplay.lastChange = dt;
      //   comp?.textDisplay?.set(entityId, textDisplay);
      // }
    }
  }
}

