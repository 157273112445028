import { WasmHandler } from 'react-lib/frameworks/WasmController';
import app from 'firebase/compat/app';
import { AnimeSpec, AnimeSpecExample } from './AnimeType';
import Anime from './Anime';

export type State = {
  lessonList?: any[] | null,
  lessonId?: string | null
  lessonData?: any,
  preferences?: any,
}

export const StateInitial = {
  lessonList: null,
  lessonId: null,
  lessonData: null,
}

export type Event =
  { message: "LNGetList", params: {} }
  | { message: "LNGetLessonData", params: { id : string } }
  | { message: "SavePreferences", params: any }
  | { message: "LNUploadVideo", params: { 
      id: string, index: number, newUpload: boolean,
      videoUrl: string, videoExt: string, thumbUrl: string, 
      setUploadModal: any, setUploading: any, setPctComplete: any } }
  | { message: "GetAnimation", params: { id: string } }
  | { message: "GetVideoList", params: { setVideoList: any } }
  | { message: "GetVideoById", params: { selectedVideo: any, setVideoUrl: any } }
  | { message: "PublishVideo", 
      params: { 
        video: any, videoData: any, filename: string, 
        setVideoList: any, setVideoUrl: any, setPublishStatus: any 
      } }

export type Handler = WasmHandler<State, Event>

export type Data = {
}

export const DataInitial: Data = {  
}

export const GetList: Handler = async (controller, params) => {
  const lessonList = ((await controller.db.collection("cache").doc("Lesson").get())
    .data()?.items || []);
  controller.setState({lessonList: lessonList});
}

export const GetLessonData: Handler = async (controller, params) => {
  if (!params?.id) return console.log("No lesson ID")
  const lessonData = (await controller.db.collection("Lesson").doc(params.id).get())?.data();
  if (lessonData) {  
    controller.setState({lessonData, lessonId: params.id});
    if (controller.getState().preferences?.lessonId !== params.id) {
      controller.handleEvent({message: "SavePreferences", params: {lessonId: params.id}});
    }
  }
  params?.setLoading(false);
}

export const UploadVideo: Handler = async (controller, params) => {
  let { id, index, newUpload, newVideoName, videoUrl, videoExt, 
    thumbUrl, setUploadModal, setUploading, setPctComplete 
  } = params;
  if (!id || !Number.isInteger(index) || !videoUrl || !videoExt) 
    return console.log("Insufficient params");
  
  let videoBlob = await (await fetch(videoUrl)).blob();
  let thumbBlob = await (await fetch(thumbUrl)).blob();
  if (!videoBlob || !thumbBlob) return console.log("No video or thumb data");

  let lessonData = controller.getState().lessonData;
  let uploadIndex = !newUpload ? index : (lessonData?.items || []).length;

  if (newUpload) {
    if (!lessonData.items[index].next.includes(uploadIndex))
      lessonData.items[index].next.push(uploadIndex);
    lessonData.items.push({name: newVideoName || "New video", next: []});
    controller.db.collection("Lesson").doc(id).update({items: lessonData.items});
  }
  
  let uploadVideoUrl = `gs://ismor-lesson/${id}/${uploadIndex}`;
  let uploadThumbUrl = `gs://ismor-lesson/${id}/thumb/${uploadIndex}`;
  
  controller.storage.refFromURL(uploadVideoUrl).put(videoBlob)
  .on(app.storage.TaskEvent.STATE_CHANGED, {
    next: (snapshot: any) => {
      setPctComplete(`Uploading video ` + 
        `${snapshot.bytesTransferred === 0 ? "" : (snapshot.bytesTransferred / snapshot.totalBytes * 100).toFixed(2) + "%"}`)
    },
    error: (error: any) => {
      console.log(error);
    },
    complete: async () => {
      controller.storage.refFromURL(uploadThumbUrl).put(thumbBlob)
      .on(app.storage.TaskEvent.STATE_CHANGED, {
        next: (snapshot: any) => {
          setPctComplete(`Uploading thumbnail ` + 
            `${snapshot.bytesTransferred === 0 ? "" : (snapshot.bytesTransferred / snapshot.totalBytes * 100).toFixed(2) + "%"}`)
        },
        error: (error: any) => {
          console.log(error);
        },
        complete: async () => {
          setUploadModal(false);
          setUploading(false);
          setPctComplete("Finished");
        }
      });
    }
  });
}

export const GetAnimation: Handler = async (controller, params) => {
  let {id, setAnimation} = params;
  if (!id || !setAnimation) return console.log("Insufficient params");
  let animationData = (await controller.db.collection("animation").doc(id).get()).data();
  setAnimation(animationData);
}

export const GetVideoList: Handler = async (controller, params) => {
  let {setVideoList} = params;
  let res = await controller.db.collection("Video").get();
  let videoList = res.docs.map(
    (doc: app.firestore.QueryDocumentSnapshot) => ({id: doc.id, ...doc.data()}));
  setVideoList(videoList);
}

export const GetVideoฺById: Handler = async (controller, params) => {
  let {selectedVideo, setVideoUrl} = params;
  if (!selectedVideo?.publishUrl) return setVideoUrl("");
  let storageweb = (controller as any).storageweb as app.storage.Storage;
  let url = await storageweb.refFromURL(selectedVideo.publishUrl).getDownloadURL();
  setVideoUrl(url);
}

export const PublishVideo: Handler = async (controller, params) => {
  let {
    video, videoData, filename, videoList, setVideoList, 
    setVideoUrl, setPublishStatus } = params;
  if (!video.id) return console.log("No video id");
  if (!videoData) return console.log("No video data");
  if (!filename) return console.log("No filename");

  let storageweb = (controller as any).storageweb as app.storage.Storage;
  if (!storageweb) return console.log("No storageweb. Can't publish");

  let dbweb = (controller as any).dbweb as app.firestore.Firestore;
  if (!dbweb) return console.log("No dbweb. Can't publish");
  
  setPublishStatus({
    status: "started",
    messages: [ "Start uploading video" ]
  });
  let publishVersion = video.nextVersion || 1;
  let publishUrl = `gs://ismor-xd-publish/Video/${video.id}/${publishVersion}/${filename}`;
  let nextVersion = publishVersion + 1;
  
  storageweb.refFromURL(publishUrl).put(videoData)
    .on(app.storage.TaskEvent.STATE_CHANGED, {
      next: (snapshot: any) => {
        setPublishStatus({
          status: "uploading",
          messages: [ `Uploading ${(snapshot.bytesTransferred / snapshot.totalBytes * 100).toFixed(2)}%` ]
        });
      },
      error: (error: any) => {
        console.log(error);
      },
      complete: async () => {
        controller.db.collection("Video").doc(video.id).update({
          nextVersion: nextVersion,
          publishUrl: publishUrl
        });
        
        let docweb = await dbweb.collection("Video").doc(video.id).get();
        if (!docweb.exists) {
          docweb.ref.set({
            name: video.name,
            publishUrl: publishUrl,
            users: []
          })
        } else {
          docweb.ref.update({publishUrl});
        }
        // Need to figure out how to update this properly so video is available right after
        setVideoList(videoList
          .map((v: any) => (
            v.id === video.id ? { ...video, publishUrl } : v
          )));
        let url = await storageweb.refFromURL(publishUrl).getDownloadURL();
        setVideoUrl(url);
        setPublishStatus(null);
      }
    });  
}
