import { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";

export default function AnimeEditor(props: any) {
  let elementType = props.item?.elementType;
  if (elementType == "rectangle") 
    return <RectangleEditor {...props} />
  else if (elementType == "text") 
    return <TextEditor {...props} />
  else
    return <></>
}

function RectangleEditor(props: any) {
  return(
    <div>
      <b>Rectangle</b>
    </div>
  )
}

function TextEditor(props: any) {
  let [text, setText] = useState("");

  useEffect(() => {
    if (props.item?.textDisplay?.text)
      setText(props.item?.textDisplay.text);
  }, [props.item]);
  
  return(
    <div>
      <b>Text</b>
      <input 
        value={text}
        onChange={(e: any) => {
          setText(e.target.value);
        }}
      />
      <Button size="mini" color="green"
        onClick={(e: any) => {
          props.modifyItem(
            props.sectionIndex,
            props.itemIndex,
            {
              ...props.item, 
              textDisplay: {...props.item?.textDisplay, text}
            }
          );
        }}>
        Save
      </Button>
    </div>
  )
}
