import React, { useEffect, useRef, useState } from "react";
import Anime from "./Anime";
import { AnimeSpec, BaseVideo, FreezePeriod } from "./AnimeType";
import AnimeEditor from "./AnimeEditor";

export default function ScreenAnimation(props: any) {
  let [animation, setAnimation] = useState<AnimeSpec | null>(null);
  let [selectedSectionIndex, setSelectedSectionIndex] = useState<number | null>(null);
  let [selectedSectionDuration, setSelectedSectionDuration] = useState(0);
  let [selectedSectionOffset, setSelectedSectionOffset] = useState(0);
  let [playing, setPlaying] = useState(false);
  let [effectToAdd, setEffectToAdd] = useState<any>(null);

  let canvasRef = useRef<HTMLCanvasElement | null>(null);
  let animeRef = useRef<Anime | null>(null);
 
  useEffect(() => {
    props.onEvent({
      // message: "GetAnimation", params: {id: "cj21ofeshH9HQ9D8CHDT", setAnimation}});
      message: "GetAnimation", params: {id: "BJd90Emn58FR4f9xuQHJ", setAnimation}});
  }, []);

  useEffect(() => {
    if (!canvasRef.current || !animation) return;
    animeRef.current = new Anime(
      canvasRef.current, 
      animation, 
      {setPlaying, setSelectedSectionIndex, setSelectedSectionDuration, setSelectedSectionOffset});
    if (animation.sections.length > 0) {
      if (selectedSectionIndex === null) {
        setSelectedSectionIndex(0);
        animeRef.current.loadFrame(0);
      } else {
        animeRef.current.loadFrame(selectedSectionIndex, selectedSectionOffset);
      }
    }
  }, [animation]);

  useEffect(() => {
    if (playing)
      setEffectToAdd(null);
  }, [playing]);
  
  const startAnimate = async (record: boolean, sectionIndex: number | null = null) => {
    if (!canvasRef.current || !animeRef.current) return;
    animeRef.current.render(record, sectionIndex);
  };

  const modifyItem = (sectionIndex: number, itemIndex: number, value: any) => {
    if (animation?.sections) {
      animation.sections[sectionIndex].items[itemIndex] = value;
      setAnimation({...animation});
    }
  }

  const setBase = (sectionIndex: number, base: any) => {
    if (animation?.sections?.length && sectionIndex < animation.sections.length
    ) {
      animation.sections[sectionIndex].base = base;
      setAnimation({...animation});
    }
  }

  const setItem = (sectionIndex: number, itemIndex: number, item: any) => {
    if (animation?.sections?.length && sectionIndex < animation.sections.length &&
        animation?.sections?.[sectionIndex]?.items && 
        itemIndex < animation.sections[sectionIndex].items.length
    ) {
      animation.sections[sectionIndex].items[itemIndex] = item;
      setAnimation({...animation});
    }
  }

  const addItem = (sectionIndex: number, item: any) => {
    if (animation?.sections) {
      animation.sections[sectionIndex].items.push(item);
      setAnimation({...animation});
    }
  }

  const addTestItem = (sectionIndex: number, effectToAdd_: any) => {
    if (effectToAdd_ === "freeze") {
    } else {
      let item = {
        elementType: effectToAdd_?.effectType,
        position: { 
          left: effectToAdd_.x, top: effectToAdd_.y, 
          visiblePeriods: [
            {start: selectedSectionOffset, stop: selectedSectionOffset + 3}
          ] 
        },
        layout: { width: effectToAdd_.width, height: effectToAdd_.height },
        textDisplay: { text: "หน้าจอนี้" }
      }
      addItem(sectionIndex, item);
    }
    setEffectToAdd(null);
  }

  const getXY = (e: React.MouseEvent<HTMLCanvasElement>) => {
    let rect = e.currentTarget.getBoundingClientRect();
    let x = e.clientX - rect.x;
    let y = e.clientY - rect.y;
    return [x, y];
  }

  // Calculate progress
  let progress;
  if (!selectedSectionDuration)
    progress = 100;
  else
    progress = selectedSectionOffset / selectedSectionDuration * 100;

  return(
    <div 
      style={{
        width: "100%", height: "95vh", border: "solid grey 1px",
        display: "flex", flexDirection: "column"
      }}>
      {/* Top Panel */}
      <div 
        style={{
          display: "flex", justifyContent: "space-between", 
          borderBottom: "solid grey 1px", backgroundColor: "lightblue"
        }}>
        <b>{animation?.name}</b>
        {!playing &&
        <button
          disabled={!animation}
          onClick={() => {}}>
          Add
        </button>}
        <div style={{flex: 1}}></div>
        <button
          disabled={!(animation && !playing)}
          onClick={() => startAnimate(false)}>
          Play All
        </button>
        <button
          disabled={!(animation && !playing)}
          onClick={() => startAnimate(true)}>
          Gen Video
        </button>
        {playing &&
        <button
          disabled={!playing}
          onClick={() => animeRef.current?.pause()}>
          Pause
        </button>}
      </div>

      {/* Main panel */}
      <div style={{display: "grid", gridTemplateColumns: "300px 1fr", flex: 1}}>
        {/* Left pane listing sections */}
        <div style={{borderRight: "solid grey 1px"}}>
          {(animation?.sections || [])
          .map((section: any, sectionIndex: number) => { 
            return(
              <div 
                key={sectionIndex} 
                style={{
                  borderBottom: "solid grey 1px",
                  backgroundColor: selectedSectionIndex === sectionIndex ? "lightgreen" : "white"
                }}
                onClick={(e: any) => {
                  setSelectedSectionIndex(sectionIndex);
                  if (animeRef.current)
                    animeRef.current.loadFrame(sectionIndex);
                }}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <b>{section?.name}</b>
                  <button
                    disabled={playing}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setSelectedSectionIndex(sectionIndex);
                      startAnimate(false, sectionIndex)
                    }}>
                    Play
                  </button>
                </div>
                <img 
                  width="300" height="200" style={{padding: "10px"}}
                  src={animeRef.current?.sections?.[sectionIndex]?.thumbUrl || "/blankscreen.png"}
                />
              </div>
            ); 
          })}
        </div>

        {/* Right pane */}
        {/*
        {animation && Number.isInteger(selectedSectionIndex) && animation.sections[selectedSectionIndex!] &&*/}
        <div style={{display: "flex", flexDirection: "column", margin: "0 10px 0 10px", height: "91vh"}}>
          {/* Tool bar */}
          <div style={{width: "100%", height: "1.5rem", borderBottom: "solid grey 1px"}}>
            {!playing &&
            <>
              <button
                style={{
                  backgroundColor: effectToAdd?.effectType === "hilight" ? "lightgreen" : "white",
                  border: "solid grey 1px",
                }}
                onClick={(e: any) => {
                  if (effectToAdd?.effectType === "hilight") {
                    setEffectToAdd(null);
                  } else {
                    setEffectToAdd({effectType: "hilight"});
                  }
                }}>
                Hilight
              </button>
              {animation?.sections[selectedSectionIndex!]?.base?.elementType === "baseVideo" &&
              <button
                style={{
                  backgroundColor: effectToAdd?.effectType === "freeze" ? "lightgreen" : "white",
                  border: "solid grey 1px",
                }}
                onClick={(e: any) => {
                  if (effectToAdd?.effectType === "freeze") {
                    setEffectToAdd(null);
                  } else {
                    let base = animation?.sections[selectedSectionIndex!].base as BaseVideo;
                    if (!base?.video?.freezePeriods)
                      base.video.freezePeriods = [];
                    base.video.freezePeriods.push({
                      start: selectedSectionOffset - base.video.freezePeriods
                                                    .reduce((acc: number, cur: any) => 
                                                            acc + cur.duration, 0), 
                      duration: 2
                    });
                    base.video.freezePeriods = base.video.freezePeriods.sort((a: any, b: any) => a.start - b.start);
                    setBase(selectedSectionIndex!, base);
                    //setEffectToAdd({effectType: "freeze"});
                  }
                }}>
                Freeze
              </button>}
            </>}
          </div>

          {/* Progress bar */}
          <div 
            style={{position: "relative", background: "grey", width: "100%", height: "5px"}}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              let bar = e.currentTarget.getBoundingClientRect();
              let dt = (e.clientX - bar.left) / bar.width * selectedSectionDuration;
              animeRef.current?.seekFrame(dt);
            }}>
            {(() => {
              if (animation?.sections?.[selectedSectionIndex!]?.base?.elementType === "baseVideo") {
                let freezePeriods = (animation.sections[selectedSectionIndex!].base as BaseVideo).video.freezePeriods || []; 
                let output = [];
                let offset = 0;
                for (var index=0; index < freezePeriods.length; index++) {
                  let period = freezePeriods[index];
                  let left = `${(period.start + offset) / selectedSectionDuration * 100}%`;
                  let width = `${period.duration / selectedSectionDuration * 100}%`;
                  offset += period.duration;
                  output.push(
                    <div
                      key={index}
                      style={{
                        position: "absolute",
                        left: left, top: 0,
                        width: width, height: "5px",
                        background: "lightblue",
                        zIndex: 10,
                      }}
                    />);
                }
                return output;
              }
              return (<></>);
            })()}
            <div style={{
              position: "absolute", background: "red", 
              left: 0, top: 0, width: `${progress}%`, height: "5px"}}/>
            <div 
              style={{
                position: "absolute", 
                left: `${progress - .75}%`, top: -5, borderRadius: "15px",
                background: "red", width: "15px", height: "15px", zIndex: 100
              }}
            />
          </div>

          {/* Canvas */}
          <div 
            style={{
              display: "flex", justifyContent:"center", backgroundColor: "black",
            }}>
            <div style={{width: "1000px", height: "500px", position: "relative"}}>
              <canvas 
                ref={canvasRef}
                style={{cursor: effectToAdd?.effectType === "hilight" ? "crosshair" : "auto"}}
                onMouseDown={(e: React.MouseEvent<HTMLCanvasElement>) => {
                  if (!effectToAdd) return;
                  let [x, y] = getXY(e);
                  let effectToAdd_ = {
                    ...effectToAdd,
                    x, y
                  };
                  setEffectToAdd(effectToAdd_);
                }}
                onMouseUp={(e: React.MouseEvent<HTMLCanvasElement>) => {
                  if (!effectToAdd) return;
                  let [x, y] = getXY(e); 
                  let effectToAdd_ = {
                    ...effectToAdd,
                    x: Math.min(x, effectToAdd.x),
                    y: Math.min(y, effectToAdd.y),
                    width: Math.abs(x - effectToAdd.x),
                    height: Math.abs(y - effectToAdd.y),
                  };
                  addTestItem(selectedSectionIndex!, effectToAdd_);
                }}
                onMouseMove={(e: React.MouseEvent<HTMLCanvasElement>) => {
                  if (!effectToAdd) return;
                  let [x, y] = getXY(e); 
                  setEffectToAdd({
                    ...effectToAdd,
                    x: Math.min(x, effectToAdd.x),
                    y: Math.min(y, effectToAdd.y),
                    width: Math.abs(x - effectToAdd.x),
                    height: Math.abs(y - effectToAdd.y),
                  });
                }}
                width="1000" height="500"
              />
              {effectToAdd && !Number.isNaN(Number(effectToAdd?.x)) 
                && !Number.isNaN(Number(effectToAdd?.y)) &&
              <svg
                width="1000" height="500" 
                style={{
                  position: "absolute", 
                  top: 0, left: 0, border: "solid red 1px" ,
                  pointerEvents: "none",
                  zIndex: 1,
                }}>
                <rect
                  stroke="red"
                  fill="none"
                  strokeWidth="4"
                  x={effectToAdd.x}
                  y={effectToAdd.y}
                  width={effectToAdd.width || 0}
                  height={effectToAdd.height || 0}
                />
              </svg>}
            </div>
          </div>

          {/* Editor */}
          <div style={{overflowY: "scroll", flex: 1}}>
            {Number.isInteger(selectedSectionIndex) && 
            (animation?.sections?.[selectedSectionIndex!]?.items || [])
            .map((item: any, itemIndex: number) => (
            <div key={itemIndex}>
              <AnimeEditor 
                item={item} 
                sectionIndex={selectedSectionIndex} 
                itemIndex={itemIndex} 
                modifyItem={modifyItem}
              />
            </div>))}
            {Number.isInteger(selectedSectionIndex) && 
            (animation?.sections?.[selectedSectionIndex!]?.items || [])
            .map((item: any, itemIndex: number) => (
            <div key={itemIndex}>
              <b>{item.elementType}</b>
              <div>
                {Object.entries(item)
                .filter((entry: any) => entry[0] !== "elementType")
                .sort((a: any, b: any) => a[0] < b[0] ? -1 : 1)
                .flatMap((entry: any, entryIndex: number) => (
                  Object.entries(entry[1])
                  .map((subEntry: any, subEntryIndex: number) => (
                    <div 
                      key={`${entryIndex}.${subEntryIndex}`}
                      style={{display: "grid", gridTemplateColumns: "50% 50%", marginLeft: "10px"}}>
                      <div>{`${entry[0]}.${subEntry[0]}`}</div>
                      <div>{JSON.stringify(subEntry?.[1])}</div>
                    </div>
                  ))
                ))}  
              </div>
            </div>))}
          </div>
        </div>
        {/*}*/}
      </div>
    </div>
  )
}
