import Anime from "../Anime"

export async function positionSystem(anime: Anime, dt: number) {
  let comp = anime.components;
  for (const entityId of anime.entities) {
    let position = comp?.position?.get(entityId);
    if (!position) continue;

    // Check visibility --------------------------------------------------------
    let visible = true;
    if (position?.visiblePeriods) {
      for (const period of position.visiblePeriods || []) {
        if (dt < period.start || (period?.stop !== undefined && dt > period.stop))
          visible = false;
      }
    }
    position.visible = visible;
    
    // Movement ----------------------------------------------------------------
    if (position && position.left && position.vleft) {
      if (position.lastChange == undefined)
        position.lastChange = 0;
      position.left = position.left + position.vleft * (dt - position.lastChange);
      position.lastChange = dt;
    }
    
    // Update position data ----------------------------------------------------
    comp?.position?.set(entityId, position);
  }
}
