import { AnimeSpec, Components, Section, AnimeCallback, FreezePeriod } from "./AnimeType";
import * as Sys from "./AnimeSystem/";

const FPS = 20;

type SectionPlus = Section & { index?: number, thumbUrl?: string };

export default class Anime {
  // Global
  canvas: HTMLCanvasElement;
  animation: AnimeSpec;
  callback: AnimeCallback;
  systems: any[];

  // Processed items
  sections: SectionPlus[] = [];
  elements: any[] = [];
  entities: number[] = [];
  components: Components | null = null;

  // Used during whole rendering
  record: boolean = false;
  mediaRecorder: MediaRecorder | null = null;
  sectionsToRender: SectionPlus[] = [];

  // Used during section rendering
  currentSection: SectionPlus | null = null;
  currentSectionIndex: number = -1;
  currentOffset: number = -1;
  startTime: number = 0;        // milliseconds
  curDuration: number = 0;      // milliseconds
  stop: number | null = null;   // milliseconds
  pauseRequested: boolean = false;

  constructor(
    canvas: HTMLCanvasElement, 
    animation: AnimeSpec, 
    callback: AnimeCallback,
  ) {
    this.canvas = canvas;
    this.animation = animation;
    this.callback = callback
    this.systems = [Sys.positionSystem, Sys.textSystem, Sys.renderingSystem];
    this.nextSection = this.nextSection.bind(this);
    this.runSection = this.runSection.bind(this); 
  }

  async prepareAnimeData() {
    this.sections = [...this.animation.sections];
    let eIndex = 0;    
    for (var sIndex = 0; sIndex < this.sections.length; sIndex++) {
      let itemIndices = [];
      for (const item of [this.sections[sIndex].base, ...this.sections[sIndex].items]) {
        itemIndices.push(eIndex);
        eIndex++;
      }
      this.sections[sIndex].index = sIndex;
      this.sections[sIndex].entities = itemIndices;
    }
    this.elements = this.sections.flatMap((section: Section) => ([section.base, ...section.items]));    
    this.components = JSON.parse(JSON.stringify(this.elements))
      .flatMap((element: Element, index: number) => (
        Object.entries(element)
          .map((pair: any) => [index, ...pair])
      ))
      .reduce((acc: Components, cur: any[]) => {
        if (!(Object.keys(acc).includes(cur[1]))) {
          (acc as any)[cur[1] as string] = new Map();
        }
        (acc as any)[cur[1]].set(cur[0], cur[2]);
        return acc;
      }, {elementType: new Map()} as Components);
    this.entities = this.elements.map((element: any, index: number) => index);
    await Sys.assetLoadSystem(this, this.curDuration / 1000);
    for (var sIndex = 0; sIndex < this.sections.length; sIndex++) {
      let section = this.sections[sIndex];
      let baseEntityId = section.entities?.[0] || 0;
      if (section.base.elementType == "baseImage") {
        section.thumbUrl = this.components?.image?.get(baseEntityId)?.thumbUrl || "/blankscreen.png";
      } else if (section.base.elementType == "baseVideo") {
        section.thumbUrl = this.components?.video?.get(baseEntityId)?.thumbUrl || "/blankscreen.png";
      }
    }
  }

  setSectionStop(section: Section) {
    if (section.base?.elementType === "baseVideo" && section?.entities && section?.entities?.length > 0) {
      let video = this.components?.video?.get(section.entities[0]);
      // video.onended = this.nextSection; // Use duration instead of onended event
      // console.log(video);
      this.stop = (video?.totalDuration || 1) * 1000;
    } else if (section.base?.elementType === "baseImage") {
      this.stop = section?.base?.image?.duration ? section.base.image.duration * 1000 : null;
    }
    this.callback?.setSelectedSectionDuration?.((this.stop  || 0) / 1000);
  }

  async systemAll(dt?: number) {
    let dt_;
    for (const system of this.systems) {
      if (dt == undefined) {           // render -> this.startTime has already been set
        this.curDuration = performance.now() - this.startTime;
        dt_ = this.curDuration / 1000;
      } else {                         // loadFrame
        dt_ = dt;
      }
      await system?.(this, dt_);
    }
    this.currentOffset = dt_ || 0;
    this.callback?.setSelectedSectionOffset?.(dt_ || 0);
  }

  async updateAnimation(animation: AnimeSpec) {
    this.animation = animation;
    // Call to process latest animation
    await this.prepareAnimeData();
  }

  async loadFrame(sectionIndex: number, dt: number=0) {
    // Call to show preview pic or video
    await this.prepareAnimeData();
    let section = this.sections[sectionIndex];
    this.entities = section?.entities || [];
    //this.startTime = -1;
    //this.curDuration = -1;
    this.setSectionStop(section);
    await this.systemAll(dt);
  }
  
  async seekFrame(dt: number=0) {
    await this.systemAll(dt);
  }

  async render(record: boolean = false, sectionIndex: number | null = null) {
    // Call to reset anime data
    await this.prepareAnimeData();
    this.pauseRequested = false;
    this.record = record;
    if (record) {
      this.mediaRecorder = this.getRecorder();
      this.mediaRecorder?.start();
    }
    if (!Number.isInteger(sectionIndex)) {
      this.sectionsToRender = [...this.sections];
    } else {
      this.sectionsToRender = [this.sections[sectionIndex!]]
    }
    this.callback?.setPlaying?.(true);
    this.nextSection();
  }

  async nextSection () {
    console.log("nextSection");
    if (this.sectionsToRender.length == 0) {
      if (this.record) {
        this.mediaRecorder?.stop();
      }
      this.callback?.setPlaying?.(false);
      console.log("All sections rendered.");
      return;
    } 
    let section = this.sectionsToRender.shift();
    if (!section) return;
    this.currentSection = section;
    this.currentSectionIndex = section.index!;
    this.callback?.setSelectedSectionIndex?.(section.index!);
    this.setSectionStop(section);
    if (section.base?.elementType === "baseVideo" && section?.entities && section?.entities?.length > 0) {
      let video = this.components?.video?.get(section.entities[0])?.video;
      // video?.play();
    }
    this.entities = section?.entities || [];
    this.startTime = -1;
    this.curDuration = -1;
    requestAnimationFrame(this.runSection);
  }

  async runSection () {
    if (this.stop && this.curDuration > this.stop) {
      this.startTime = -1;
      this.curDuration = -1;
      this.nextSection();
    } else {
      if (this.startTime === -1) {
        this.startTime = performance.now();
      }
      await this.systemAll();
      if (this.pauseRequested) {
        this.pauseRequested = false;
        if (this.currentSection?.base?.elementType == "baseVideo") {
          let video = this.components?.video?.get(this.entities[0])?.video;
          if (video)
            video.pause();
        }
        this.pauseFinal();
      } else {
        requestAnimationFrame(this.runSection);
      }
    }
  }

  pause() {
    if (!this.pauseRequested) {
      this.pauseRequested = true;
    }
  }

  pauseFinal() {
    this.callback?.setPlaying?.(false);
  }

  getRecorder(): MediaRecorder {
    let mediaRecorder: MediaRecorder | null = null;
    mediaRecorder = new MediaRecorder(
      this.canvas.captureStream(FPS), 
      { mimeType: 'video/webm; codes=vp9'});
    let recordedChunks: any[] = [];
    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
          recordedChunks.push(event.data);
      }
    };
    mediaRecorder.onstop = () => {
      console.log("stop");
      const blob = new Blob(recordedChunks, { type: 'video/webm' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'animation.webm';
      a.click();
      recordedChunks = [];
    };
    return mediaRecorder
  }
}
